<template>
  <v-skeleton-loader type="list-item-three-line" v-if="isLoading" />

  <v-card class="card-movement" v-else>
    <v-card-title class="card-movement--title">
      Informações de movimentações
    </v-card-title>
    <v-card-text class="pt-5">
      <v-row>
        <v-col
          class="card-movement__items"
          cols="4"
          v-for="(item, index) in model.movements"
          :key="index"
        >
          <v-icon size="12" :color="getColor(item.title)">
            fa-solid fa-circle
          </v-icon>
          <div class="d-flex flex-column">
            <span class="card-movement__items--text">
              {{ item.title }}
            </span>
            <span class="card-movement__items--description">
              {{ item.value }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { DashboardService } from '@/services/api/dashboard';

export default {
  props: {
    contractId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    isLoading: false,
    model: {
      activeLives: 0,
      movements: []
    }
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      try {
        this.isLoading = true;
        const dashboardService = new DashboardService(false);
        const { status, data } = await dashboardService.getMovementCardData(
          this.contractId
        );

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    getColor(situation) {
      let color = {
        Ativos: 'success',
        'Em análise': 'warning',
        Inativos: 'error',
        'Inativos com vínculo': 'primary',
        Aposentados: 'info',
        'Em remissão': 'black',
        default: 'primary'
      };

      return color[situation] || color['default'];
    }
  },

  watch: {
    contractId() {
      this.search();
    }
  }
};
</script>

<style lang="scss">
.card-movement {
  &--title {
    border-bottom: 1px solid;
    font-weight: 400;
    font-size: 18px;
  }

  &__resume,
  &__items {
    font-family: $font-face-primary;
  }

  &__resume {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
    border-right: 1px solid;

    @media (max-width: 600px) {
      border-bottom: 1px solid;
      border-right: 0;
      padding-bottom: 1rem;
    }

    p {
      margin-bottom: 0;
    }

    h6 {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: 0.0094rem;
    }
  }

  &__avatar {
    height: 48px;
    width: 48px;
    font-size: 18px;
    border-radius: 6px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;
    text-align: center;
    vertical-align: middle;
  }

  &__items {
    display: flex;
    flex-direction: row;
    gap: 0.6rem;
    align-items: baseline;

    &--text {
      font-size: 12px;
    }

    &--description {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
    }
  }
}

.theme--light {
  .card-movement {
    &--title {
      border-color: #c6c5ce;
      color: #7a7786;
    }

    &__resume {
      border-color: $gray--15;

      p {
        color: $gray--30;
      }
    }

    &__items {
      &--text {
        color: $gray--30;
      }
    }
  }
}

.theme--dark {
  .card-movement {
    &--title {
      border-color: $gray--40;
      color: $gray--20;
    }

    &__resume {
      border-color: $gray--40;
    }
  }
}
</style>
